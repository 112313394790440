import React from 'react';
import PropTypes from 'prop-types';

import { TextAreaContainer, TextAreaElement, TextAreaLabel } from './styles';

class TextArea extends React.Component {
    state = { Value: this.props.Value };

    onChange = event => {
        this.setState({ Value: event.target.value });
    }

    render() {
        var { Value } = this.state;
        var { className, ControlledComponent, Label, MaxRows, NoMargin, NumRows, OnBlur, OnChange, OnKeyPress, Placeholder, Value: PropsValue } = this.props;

        if (ControlledComponent) Value = PropsValue;
        else OnChange = this.onChange;

        return (
            <TextAreaContainer className={className} NoMargin={NoMargin}>
                {
                    Label ?
                    <TextAreaLabel>{Label}</TextAreaLabel>
                :
                    null
                }
                <TextAreaElement
                    maxRows={MaxRows}
                    rows={NumRows || 8}
                    placeholder={Placeholder}
                    onBlur={OnBlur}
                    onChange={OnChange}
                    onKeyPress={OnKeyPress}
                    type="textarea"
                    value={Value}
                />
            </TextAreaContainer>
            
        );
    }
}

TextArea.propTypes = {
    ControlledComponent: PropTypes.bool,
    Label: PropTypes.string,
    MaxRows: PropTypes.number,
    NoMargin: PropTypes.bool,
    NumRows: PropTypes.number,
    OnBlur: PropTypes.func,
    OnChange: PropTypes.func,
    OnKeyPress: PropTypes.func,
    Placeholder: PropTypes.string,
    Value: PropTypes.string.isRequired
}

export default TextArea;