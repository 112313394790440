import {
    TRYING_GET_CITIES,
    TRYING_GET_CITIES_SUCCESS,
    TRYING_GET_CITIES_ERROR,

    TRYING_GET_COUNTRIES,
    TRYING_GET_COUNTRIES_SUCCESS,
    TRYING_GET_COUNTRIES_ERROR,
    
    TRYING_GET_STATES,
    TRYING_GET_STATES_SUCCESS,
    TRYING_GET_STATES_ERROR,
    
    TRYING_REGISTER_USER,
    TRYING_REGISTER_USER_SUCCESS,
    TRYING_REGISTER_USER_ERROR,

    TRYING_VIEW_AVAILABLE_LANGUAGES,
    TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS,
    TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR,

    TRYING_VIEW_CONTRACT_DETAIL,
    TRYING_VIEW_CONTRACT_DETAIL_SUCCESS
} from '../Types';

const INITIAL_STATE = {
    AvailableLanguages: [],
    IsPreRegistered: null,
    TrainerRequests: [],

    Cities: {},
    Countries: [],
    States: {},

    TryingGetCities: false,
    TryingGetCitiesError: null,
    TryingGetCountries: false,
    TryingGetCountriesError: null,
    TryingGetStates: false,
    TryingGetStatesError: null,
    TryingRegisterUser: false,
    TryingRegisterUserError: null,
    TryingViewAvailableLanguages: false,
    TryingViewAvailableLanguagesError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_GET_CITIES:
            return { ...state, TryingGetCities: true, TryingGetCitiesError: null };
        case TRYING_GET_CITIES_ERROR:
            return { ...state, TryingGetCities: false, TryingGetCitiesError: action.payload };
        case TRYING_GET_CITIES_SUCCESS:
            var { Cities, StateId } = action.payload;

            var NewCities = { ...state.Cities };
            NewCities[`${StateId}`] = Cities;

            return { ...state, Cities: NewCities, TryingGetCities: false, TryingGetCitiesError: null };

        case TRYING_GET_COUNTRIES:
            return { ...state, TryingGetCountries: true, TryingGetCountriesError: null };
        case TRYING_GET_COUNTRIES_ERROR:
            return { ...state, TryingGetCountries: false, TryingGetCountriesError: action.payload };
        case TRYING_GET_COUNTRIES_SUCCESS:
            return { ...state, Countries: action.payload.Countries, TryingGetCountries: false, TryingGetCountriesError: null };

        case TRYING_GET_STATES:
            return { ...state, TryingGetStates: true, TryingGetStatesError: null };
        case TRYING_GET_STATES_ERROR:
            return { ...state, TryingGetStates: false, TryingGetStatesError: action.payload };
        case TRYING_GET_STATES_SUCCESS:
            var { States, CountryId } = action.payload;

            var NewStates = { ...state.States };
            NewStates[`${CountryId}`] = States;

            return { ...state, States: NewStates, TryingGetStates: false, TryingGetStatesError: null };

        case TRYING_REGISTER_USER:
            return { ...state, TryingRegisterUser: true, TryingRegisterUserError: null };
        case TRYING_REGISTER_USER_ERROR:
            return { ...state, TryingRegisterUser: false, TryingRegisterUserError: action.payload };
        case TRYING_REGISTER_USER_SUCCESS:
            return { ...state, TryingRegisterUser: false, TryingRegisterUserError: null };

        case TRYING_VIEW_AVAILABLE_LANGUAGES:
            return { ...state, TryingViewAvailableLanguages: true, TryingViewAvailableLanguagesError: null };
        case TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR:
            return { ...state, TryingViewAvailableLanguages: false, TryingViewAvailableLanguagesError: action.payload };
        case TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS:
            return { ...state, AvailableLanguages: action.payload.Languages, TryingViewAvailableLanguages: false, TryingViewAvailableLanguagesError: null };

        case TRYING_VIEW_CONTRACT_DETAIL:
            return { ...state, TryingRegisterUserError: null };
        case TRYING_VIEW_CONTRACT_DETAIL_SUCCESS:
            var { IsPreRegistered, TrainerRequests } = action.payload;

            return { ...state, IsPreRegistered, TrainerRequests };
        
        default:
            return state;
    }
};
