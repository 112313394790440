import moment from 'moment';

import {
    TRYING_ACCEPT_CONTRACT,
    TRYING_ACCEPT_CONTRACT_SUCCESS,
    TRYING_ACCEPT_CONTRACT_ERROR,

    TRYING_REJECT_CONTRACT,
    TRYING_REJECT_CONTRACT_SUCCESS,
    TRYING_REJECT_CONTRACT_ERROR,

    TRYING_RESPOND_TO_TRAINER_REQUEST,
    TRYING_RESPOND_TO_TRAINER_REQUEST_SUCCESS,
    TRYING_RESPOND_TO_TRAINER_REQUEST_ERROR,

    TRYING_VIEW_CONTRACT_DETAIL,
    TRYING_VIEW_CONTRACT_DETAIL_SUCCESS,
    TRYING_VIEW_CONTRACT_DETAIL_ERROR
} from '../Types';

const INITIAL_STATE = {
    ContractDetail: {},

    TryingAcceptContract: false,
    TryingAcceptContractError: null,
    TryingRejectContract: false,
    TryingRejectContractError: null,
    TryingRespondToTrainerRequest: false,
    TryingRespondToTrainerRequestError: null,
    TryingViewContractDetail: false,
    TryingViewContractDetailError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ACCEPT_CONTRACT:
            return { ...state, TryingAcceptContract: true, TryingAcceptContractError: null };
        case TRYING_ACCEPT_CONTRACT_ERROR:
            return { ...state, TryingAcceptContract: false, TryingAcceptContractError: action.payload };
        case TRYING_ACCEPT_CONTRACT_SUCCESS:
            return { ...state, ContractDetail: { ...state.ContractDetail, UserSigned: moment() }, TryingAcceptContract: false, TryingAcceptContractError: null };

        case TRYING_REJECT_CONTRACT:
            return { ...state, TryingRejectContract: true, TryingRejectContractError: null };
        case TRYING_REJECT_CONTRACT_ERROR:
            return { ...state, TryingRejectContract: false, TryingRejectContractError: action.payload };
        case TRYING_REJECT_CONTRACT_SUCCESS:
            return { ...state, TryingRejectContract: false, TryingRejectContractError: null };

        case TRYING_RESPOND_TO_TRAINER_REQUEST:
            return { ...state, TryingRespondToTrainerRequest: true, TryingRespondToTrainerRequestError: null };
        case TRYING_RESPOND_TO_TRAINER_REQUEST_ERROR:
            return { ...state, TryingRespondToTrainerRequest: false, TryingRespondToTrainerRequestError: action.payload };
        case TRYING_RESPOND_TO_TRAINER_REQUEST_SUCCESS:
            return { ...state, TryingRespondToTrainerRequest: false, TryingRespondToTrainerRequestError: null };

        case TRYING_VIEW_CONTRACT_DETAIL:
            return { ...state, TryingViewContractDetail: true, TryingViewContractDetailError: null };
        case TRYING_VIEW_CONTRACT_DETAIL_ERROR:
            return { ...state, TryingViewContractDetail: false, TryingViewContractDetailError: action.payload };
        case TRYING_VIEW_CONTRACT_DETAIL_SUCCESS:
            return { ...state, ContractDetail: action.payload.ContractDetail, TryingViewContractDetail: false, TryingViewContractDetailError: null };
        
        default:
            return state;
    }
};
