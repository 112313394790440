import React from 'react';
import PropTypes from 'prop-types';
import { Container, MainContentContainer, PageContainer } from './styles';

import Breadcrumbs from '../Breadcrumbs';
import Spacer from '../Spacer';
import TopBar from '../TopBar';

class Page extends React.Component {
    render() {
        var { children, className, ContentWidth, MobileFriendly, NoBackground, NoMargin, NoTopSpacer, PageWidth, renderBreadcrumbs, renderSubmitButton, renderTopBar } = this.props;

        return (
            <Container className={`page-container ${className}`} NoMargin={NoMargin} PageWidth={PageWidth}>
                {renderBreadcrumbs && <Breadcrumbs Breadcrumbs={renderBreadcrumbs} MobileFriendly={MobileFriendly} />}
                
                {(!NoMargin || NoTopSpacer) && <Spacer Size="small" />}

                <PageContainer className="page-pagecontainer" NoBackground={NoBackground}>
                    {renderTopBar && <TopBar BackgroundColor={this.props.renderTopBar} />}
                    
                    <MainContentContainer className="page-maincontentcontainer" ContentWidth={ContentWidth}>
                        {children}
                    </MainContentContainer>

                    {renderSubmitButton && renderSubmitButton()}
                </PageContainer>
            </Container>
        )
    }
}

Page.propTypes = {
    ContentWidth: PropTypes.string,
    MobileFriendly: PropTypes.bool,
    NoBackground: PropTypes.bool,
    NoMargin: PropTypes.bool,
    NoTopSpacer: PropTypes.bool,
    PageWidth: PropTypes.string,
    renderBreadcrumbs: PropTypes.array,
    renderSubmitButton: PropTypes.func,
    renderTopBar: PropTypes.string,
    SplitButtons: PropTypes.bool
}

Page.defaultProps = {
    SplitButtons: false
}

export default Page;