export const HANDLE_VISIBILITY_CHANGE = 'HANDLE_VISIBILITY_CHANGE';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';

export const TRYING_ACCEPT_CONTRACT = 'TRYING_ACCEPT_CONTRACT';
export const TRYING_ACCEPT_CONTRACT_SUCCESS = 'TRYING_ACCEPT_CONTRACT_SUCCESS';
export const TRYING_ACCEPT_CONTRACT_ERROR = 'TRYING_ACCEPT_CONTRACT_ERROR';

export const TRYING_CHARGE_PAYMENT = 'TRYING_CHARGE_PAYMENT';
export const TRYING_CHARGE_PAYMENT_SUCCESS = 'TRYING_CHARGE_PAYMENT_SUCCESS';
export const TRYING_CHARGE_PAYMENT_ERROR = 'TRYING_CHARGE_PAYMENT_ERROR';

export const TRYING_GET_CITIES = 'TRYING_GET_CITIES';
export const TRYING_GET_CITIES_SUCCESS = 'TRYING_GET_CITIES_SUCCESS';
export const TRYING_GET_CITIES_ERROR = 'TRYING_GET_CITIES_ERROR';

export const TRYING_GET_COUNTRIES = 'TRYING_GET_COUNTRIES';
export const TRYING_GET_COUNTRIES_SUCCESS = 'TRYING_GET_COUNTRIES_SUCCESS';
export const TRYING_GET_COUNTRIES_ERROR = 'TRYING_GET_COUNTRIES_ERROR';

export const TRYING_GET_STATES = 'TRYING_GET_STATES';
export const TRYING_GET_STATES_SUCCESS = 'TRYING_GET_STATES_SUCCESS';
export const TRYING_GET_STATES_ERROR = 'TRYING_GET_STATES_ERROR';

export const TRYING_INITIATE_INVOICE_PAYMENT = 'TRYING_INITIATE_INVOICE_PAYMENT';
export const TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS = 'TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS';
export const TRYING_INITIATE_INVOICE_PAYMENT_ERROR = 'TRYING_INITIATE_INVOICE_PAYMENT_ERROR';

export const TRYING_REGISTER_USER = 'TRYING_REGISTER_USER';
export const TRYING_REGISTER_USER_SUCCESS = 'TRYING_REGISTER_USER_SUCCESS';
export const TRYING_REGISTER_USER_ERROR = 'TRYING_REGISTER_USER_ERROR';

export const TRYING_REJECT_CONTRACT = 'TRYING_REJECT_CONTRACT';
export const TRYING_REJECT_CONTRACT_SUCCESS = 'TRYING_REJECT_CONTRACT_SUCCESS';
export const TRYING_REJECT_CONTRACT_ERROR = 'TRYING_REJECT_CONTRACT_ERROR';

export const TRYING_RESPOND_TO_TRAINER_REQUEST = 'TRYING_RESPOND_TO_TRAINER_REQUEST';
export const TRYING_RESPOND_TO_TRAINER_REQUEST_SUCCESS = 'TRYING_RESPOND_TO_TRAINER_REQUEST_SUCCESS';
export const TRYING_RESPOND_TO_TRAINER_REQUEST_ERROR = 'TRYING_RESPOND_TO_TRAINER_REQUEST_ERROR';

export const TRYING_UPDATE_CONFIRM_DELIVERY = 'TRYING_UPDATE_CONFIRM_DELIVERY';
export const TRYING_UPDATE_CONFIRM_DELIVERY_SUCCESS = 'TRYING_UPDATE_CONFIRM_DELIVERY_SUCCESS';
export const TRYING_UPDATE_CONFIRM_DELIVERY_ERROR = 'TRYING_UPDATE_CONFIRM_DELIVERY_ERROR';

export const TRYING_VALIDATE_USER = 'TRYING_VALIDATE_USER';
export const TRYING_VALIDATE_USER_SUCCESS = 'TRYING_VALIDATE_USER_SUCCESS';
export const TRYING_VALIDATE_USER_ERROR = 'TRYING_VALIDATE_USER_ERROR';

export const TRYING_VIEW_AVAILABLE_LANGUAGES = 'TRYING_VIEW_AVAILABLE_LANGUAGES';
export const TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS = 'TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS';
export const TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR = 'TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR';

export const TRYING_VIEW_CONFIRM_DELIVERY = 'TRYING_VIEW_CONFIRM_DELIVERY';
export const TRYING_VIEW_CONFIRM_DELIVERY_SUCCESS = 'TRYING_VIEW_CONFIRM_DELIVERY_SUCCESS';
export const TRYING_VIEW_CONFIRM_DELIVERY_ERROR = 'TRYING_VIEW_CONFIRM_DELIVERY_ERROR';

export const TRYING_VIEW_CONTRACT_DETAIL = 'TRYING_VIEW_CONTRACT_DETAIL';
export const TRYING_VIEW_CONTRACT_DETAIL_SUCCESS = 'TRYING_VIEW_CONTRACT_DETAIL_SUCCESS';
export const TRYING_VIEW_CONTRACT_DETAIL_ERROR = 'TRYING_VIEW_CONTRACT_DETAIL_ERROR';

export const TRYING_VIEW_INVOICE_DETAIL = 'TRYING_VIEW_INVOICE_DETAIL';
export const TRYING_VIEW_INVOICE_DETAIL_SUCCESS = 'TRYING_VIEW_INVOICE_DETAIL_SUCCESS';
export const TRYING_VIEW_INVOICE_DETAIL_ERROR = 'TRYING_VIEW_INVOICE_DETAIL_ERROR';