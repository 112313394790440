import {
    TRYING_UPDATE_CONFIRM_DELIVERY,
    TRYING_UPDATE_CONFIRM_DELIVERY_SUCCESS,
    TRYING_UPDATE_CONFIRM_DELIVERY_ERROR,

    TRYING_VIEW_CONFIRM_DELIVERY,
    TRYING_VIEW_CONFIRM_DELIVERY_SUCCESS,
    TRYING_VIEW_CONFIRM_DELIVERY_ERROR
} from '../Types';

const INITIAL_STATE = {
    Deliveries: [],

    TryingUpdateConfirmDelivery: false,
    TryingUpdateConfirmDeliveryError: null,
    TryingViewConfirmDelivery: false,
    TryingViewConfirmDeliveryError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_UPDATE_CONFIRM_DELIVERY:
            return { ...state, TryingUpdateConfirmDelivery: true, TryingUpdateConfirmDeliveryError: null };
        case TRYING_UPDATE_CONFIRM_DELIVERY_ERROR:
            return { ...state, TryingUpdateConfirmDelivery: false, TryingUpdateConfirmDeliveryError: action.payload };
        case TRYING_UPDATE_CONFIRM_DELIVERY_SUCCESS:
            let Deliveries = [ ...state.Deliveries ];
            Deliveries.shift();

            return { ...state, Deliveries, TryingUpdateConfirmDelivery: false, TryingUpdateConfirmDeliveryError: null };

        case TRYING_VIEW_CONFIRM_DELIVERY:
            return { ...state, TryingViewConfirmDelivery: true, TryingViewConfirmDeliveryError: null };
        case TRYING_VIEW_CONFIRM_DELIVERY_ERROR:
            return { ...state, TryingViewConfirmDelivery: false, TryingViewConfirmDeliveryError: action.payload };
        case TRYING_VIEW_CONFIRM_DELIVERY_SUCCESS:
            return { ...state, Deliveries: action.payload.Deliveries, TryingViewConfirmDelivery: false, TryingViewConfirmDeliveryError: null };
        
        default:
            return state;
    }
};
