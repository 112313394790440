import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UpdateConfirmDelivery, ViewConfirmDelivery } from '../../Services/Actions';

import { DeliveryContainer, DeliveriesList } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { SetLanguage } from '../../Functions';

import { ReturnUrl } from '../../Config';

class ConfirmDelivery extends React.Component {
    state = { Loading: true };

    componentDidMount() {
        this._isMounted = true;

        // Set Language To Be Norwegian By Default
            SetLanguage(2);

        this.onViewConfirmDelivery();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onChargePayment = PaymentId => {
        var { InvoiceId } = this.props.match.params;

        if (!this.props.TryingChargePayment) this.setState({ Loading: true }, () => this.props.ChargePayment({ InvoiceId, PaymentId }).then(() => this.props.ViewInvoiceDetail({ InvoiceId })).then(() => this.setState({ Loading: false })));
        // this.setState({ Loading: true }, () => this.props.ViewInvoiceDetail({ InvoiceId })).then(() => this.setState({ Loading: false }));
    }

    onStartPaymentFlow = () => {
        var { InvoiceId } = this.props.match.params;
        var { IntegrationType } = this.state;

        if (!this.props.TryingInitiateInvoicePayment) {
            this.props.InitiateInvoicePayment({ IntegrationType, InvoiceId, ReturnUrl: `${ReturnUrl}${InvoiceId}`, Url: `${ReturnUrl}${InvoiceId}` }).then(({ HostedPaymentPageUrl }) => {
                window.location.href = HostedPaymentPageUrl;
            });
        }
    }

    onUpdateConfirmDelivery = ({ ConfirmDeliveryId, DeliveryStatus}) => {
        this.props.UpdateConfirmDelivery({ ConfirmDeliveryId, DeliveryStatus });
    }

    onViewConfirmDelivery = () => {
        var { UserId } = this.props.match.params;

        this.props.ViewConfirmDelivery({ UserId }).then(() => {
            this.setState({ Loading: false });
        });
    }

    renderDeliveries = () => {
        var { t } = this.props;
        var { Deliveries } = this.props;

        if (!Deliveries.length) return <PageText FontFamily="medium" FontSize="medium" JustifyContent="center" NoMargin Text={t('_no_confirm_deliveries')} TextAlign="center" />;

        return (
            <DeliveriesList className="DeliveriesList">
                {
                    Deliveries.map(({ ConfirmDeliveryId, DisplayName, ProductName }, index) => {
                        return (
                            <React.Fragment key={index}>
                                {/* {index !== Deliveries.length - 1 && index !== 0 && <Spacer Size="small" />} */}

                                <DeliveryContainer className="DeliveryContainer">
                                    <MultiText
                                        Texts={[
                                            { FontFamily: 'medium', Text: DisplayName },
                                            { FontFamily: 'medium', Text: t('_confirm_product_delivery2') }
                                        ]}
                                    />

                                    <Spacer Size="extra-small" />

                                    <PageText FontFamily="semibold" FontSize="medium" JustifyContent="center" NoMargin TextAlign="center" Text={ProductName} />

                                    <Spacer Size="extra-small" />

                                    <PageText FontFamily="medium" FontSize="medium" JustifyContent="center" NoMargin TextAlign="center" Text={t('_confirm_product_delivery3')} />

                                    <Spacer Size="extra-small" />

                                    <ButtonGroup
                                        Buttons={[
                                            { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateConfirmDelivery({ ConfirmDeliveryId, DeliveryStatus: 'accept' }), Title: t('_i_confirm') },
                                            { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateConfirmDelivery({ ConfirmDeliveryId, DeliveryStatus: 'reject' }), Title: t('_i_reject') }
                                        ]}
                                    />
                                </DeliveryContainer>
                            </React.Fragment>
                        );
                    })
                }
            </DeliveriesList>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;

        return (
            <>
                {this.props.TryingUpdateConfirmDelivery && <Loading />}
                
                <Page>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('_confirm_product_delivery1')} TextAlign="center" />

                    <Spacer Size="medium" />

                    {this.renderDeliveries()}
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        Deliveries: state.ConfirmDelivery.Deliveries,
        
        TryingUpdateConfirmDelivery: state.ConfirmDelivery.TryingUpdateConfirmDelivery,
        TryingUpdateConfirmDeliveryError: state.ConfirmDelivery.TryingUpdateConfirmDeliveryError,
        TryingViewConfirmDelivery: state.ConfirmDelivery.TryingViewConfirmDelivery,
        TryingViewConfirmDeliveryError: state.ConfirmDelivery.TryingViewConfirmDeliveryError,
    };
};

export default withTranslation()(connect(mapStateToProps, { UpdateConfirmDelivery, ViewConfirmDelivery } )(ConfirmDelivery));