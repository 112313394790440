import i18n from "i18next";
import moment from 'moment';
import 'moment/locale/nb';

var English_LocaleUpdated = false;
var Norwegian_LocaleUpdated = false;

export function getBorderRadiusFromHeight(Size) {
    switch (Size) {
        case "small":
            return '15px';
        case "medium":
            return '20px';
        case "large":
            return '25px';
        case "extra-large":
            return '33px';
        default:
            return '15px';
    }
}

export function GetNetsLanguage(LanguageId) {
    // English - en-GB
    // Danish - da-DK
    // Swedish - sv-SE
    // Norwegian - nb-NO
    // German - de-DE
    // Polish - pl-PL
    // French - fr-FR
    // Spanish - es-ES
    // Dutch - nl-NL
    // Finnish - fi-FI

    switch(+LanguageId) {
        case 1:
            return 'en-GB';
        case 2:
            return 'nb-NO';

        default:
            return 'en-GB';
    }

}

export const RenderCurrencyText = ({ CurrencySign, Price, SignUsed }) => {
    var CurrencyText = '0';

    // Add Decimals Only If Not Integer
        if (Price % Math.floor(Price) !== 0) Price = parseFloat(Price).toFixed(2);
    
    if (SignUsed === 'After') CurrencyText = `${Price} ${CurrencySign}`;
    else if (SignUsed === 'Before') CurrencyText = `${CurrencySign}${Price}`;

    return CurrencyText;
}

export const SetLanguage = LanguageId => {
    LanguageId = +LanguageId;

    moment.locale(LanguageId === 1 ? 'en' : 'nb');
    i18n.changeLanguage(LanguageId === 1 ? 'en' : 'no');

    if (LanguageId === 1 && !English_LocaleUpdated) {
        moment.relativeTimeThreshold('ss', 0);

        moment.updateLocale('en', {
            longDateFormat: {
                "[NumbersDate]": "MM-DD-YYYY",
                "[TrackingHistory]": "MM-DD-YYYY hh:mm a",
                "[WeeklyViewDayHeader]" : "MMM D",
                "[WeeklyViewHeader]": "MMM DD, YYYY"
            },
            relativeTime : {
                future: "in %s",
                past:   "%s",
                ss: '%d s',
                m:  "1 m",
                mm: "%d m",
                h:  "1 h",
                hh: "%d h",
                d:  "1 d",
                dd: "%d d",
                w:  "1 w",
                ww: "%d w",
                M:  "1 mn",
                MM: "%d mn",
                y:  "1 y",
                yy: "%d y"
            }
        });

        English_LocaleUpdated = true;
    }
    else if (LanguageId === 2 && !Norwegian_LocaleUpdated) {
        moment.relativeTimeThreshold('ss', 0);

        moment.updateLocale('nb', {
            longDateFormat: {
                "[NumbersDate]": "DD.MM.YYYY",
                "[TrackingHistory]": "DD.MM.YYYY HH:mm",
                "[WeeklyViewDayHeader]" : "DD. MMM",
                "[WeeklyViewHeader]": "DD. MMM YYYY"
            },
            relativeTime : {
                future: "i %s",
                past:   "%s",
                ss: '%d s',
                m:  "1 m",
                mm: "%d m",
                h:  "1 t",
                hh: "%d t",
                d:  "1 d",
                dd: "%d d",
                w:  "1 u",
                ww: "%d u",
                M:  "1 mn",
                MM: "%d mn",
                y:  "1 å",
                yy: "%d å"
            }
        });

        Norwegian_LocaleUpdated = true;
    }
}