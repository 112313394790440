import styled from 'styled-components';

import TextareaAutosize from 'react-autosize-textarea';

export const TextAreaContainer = styled.div`
    ${props => props.NoMargin ? '' : 'margin-bottom: 15px;'}
`;

export const TextAreaElement = styled(TextareaAutosize)`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: var(--black);
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    line-height: 21px;
    padding: 5px 10px;
    resize: vertical;
    width: 100%;

    ::placeholder {
        color: var(--gray-silver);
        opacity: 1;
    }
    
    :-ms-input-placeholder {
        color: var(--gray-silver);
    }
    
    ::-ms-input-placeholder {
        color: var(--gray-silver);
    }
`;

export const TextAreaLabel = styled.div`
    font-family: var(--text-semibold);
    margin-bottom: 10px;
`;