import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ChargePayment, InitiateInvoicePayment, ViewInvoiceDetail } from '../../Services/Actions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

// import { UnpaidPayout, UnpaidPayoutsContainer, UnpaidPayoutsHeader, UnpaidPayoutsList } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { SetLanguage } from '../../Functions';

import { ReturnUrl } from '../../Config';

class Invoice extends React.Component {
    state = { Loading: true, IntegrationType: 'EmbeddedCheckout' };

    componentDidMount() {
        this._isMounted = true;

        this.onViewInvoiceDetail();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onChargePayment = PaymentId => {
        var { InvoiceId } = this.props.match.params;

        if (!this.props.TryingChargePayment) this.setState({ Loading: true }, () => this.props.ChargePayment({ InvoiceId, PaymentId }).then(() => this.props.ViewInvoiceDetail({ InvoiceId })).then(() => this.setState({ Loading: false })));
        // this.setState({ Loading: true }, () => this.props.ViewInvoiceDetail({ InvoiceId })).then(() => this.setState({ Loading: false }));
    }

    onStartPaymentFlow = () => {
        var { InvoiceId } = this.props.match.params;
        var { IntegrationType } = this.state;

        if (!this.props.TryingInitiateInvoicePayment) {
            this.props.InitiateInvoicePayment({ IntegrationType, InvoiceId, ReturnUrl: `${ReturnUrl}${InvoiceId}`, Url: `${ReturnUrl}${InvoiceId}` }).then(({ HostedPaymentPageUrl }) => {
                window.location.href = HostedPaymentPageUrl;
            });
        }
    }

    onViewInvoiceDetail = () => {
        var { InvoiceId } = this.props.match.params;

        this.props.ViewInvoiceDetail({ InvoiceId }).then(() => {
            var { LanguageId } = this.props.InvoiceDetail.ContractDetails;

            if (!!+LanguageId) SetLanguage(+LanguageId);

            this.setState({ Loading: false }, () => {
                const QueryValues = queryString.parse(this.props.location.search);

                if (Object.entries(QueryValues).length > 0 && QueryValues.paymentid) this.onChargePayment(QueryValues.paymentid);
            });
        });
    }

    render() {
        if (this.state.Loading || this.props.TryingViewInvoiceDetail) return <Loading />;

        var { t } = this.props;
        var { InvoiceDetail: { ContractDetails: { ContractId, Trainer, UserSigned }, InvoiceDetails: { AmountText, InvoiceOrder, PaymentDueDate, PaymentMadeDate, TotalInvoices } }, TryingInitiateInvoicePayment } = this.props;

        var DaysNumber_PaymentDueDate = moment(PaymentDueDate).diff(moment().format('YYYY-MM-DD'), 'days');

        // PaymentMadeDate = moment();

        if (!!PaymentMadeDate) {
            return (
                <>
                    {(TryingInitiateInvoicePayment) && <Loading />}

                    <Page>
                        <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text="Payment succeeded" TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`This payment was made on ${moment(PaymentMadeDate).format('[WeeklyViewHeader]')}`} TextAlign="center" />

                        <Spacer Size="medium" />

                        <Link target="_blank" to={`/contract/${ContractId}`}>
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('view_contract')} TextAlign="left" />
                        </Link>
                    </Page>
                </>
            );
        }

        return (
            <>
                {(TryingInitiateInvoicePayment) && <Loading />}

                <Page>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('make_payment')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('make_payment_info', { DateTime: moment(UserSigned).format('[WeeklyViewHeader]'), InvoiceNo: InvoiceOrder, Trainer, TotalInvoices })} TextAlign="center" />

                    <Spacer Size="medium" />

                    <Link target="_blank" to={`/contract/${ContractId}`}>
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('view_contract')} TextAlign="left" />
                    </Link>

                    <Spacer Size="medium" />

                    <MultiText
                        Texts={[
                            { FontFamily: 'medium', Text: `${t('contracts_invoice_amount')}:` },
                            { FontFamily: 'semibold', Text: AmountText }
                        ]}
                    />

                    <Spacer Size="extra-extra-small" />

                    <MultiText
                        Texts={[
                            { FontFamily: 'medium', Text: `${t('contracts_invoice_deadline_date')}:` },
                            { FontFamily: 'semibold', Text: `${moment(PaymentDueDate).format('[NumbersDate]')} (${t('in_days', { DaysNumber: DaysNumber_PaymentDueDate })})` }
                        ]}
                    />

                    <Spacer Size="extra-extra-small" />

                    <ButtonGroup
                        Buttons={[
                            { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Color: 'white', ColorHover: 'white', OnClick: this.onStartPaymentFlow, Title: t('pay_invoice', { Amount: AmountText }) }
                        ]}
                    />

                    <div id="dibs-complete-checkout"></div>
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        InvoiceDetail: state.Invoice.InvoiceDetail,

        TryingChargePayment: state.Invoice.TryingChargePayment,
        TryingChargePaymentError: state.Invoice.TryingChargePaymentError,
        TryingInitiateInvoicePayment: state.Invoice.TryingInitiateInvoicePayment,
        TryingInitiateInvoicePaymentError: state.Invoice.TryingInitiateInvoicePaymentError,
        TryingViewInvoiceDetail: state.Invoice.TryingViewInvoiceDetail,
        TryingViewInvoiceDetailError: state.Invoice.TryingViewInvoiceDetailError
    };
};

export default withTranslation()(connect(mapStateToProps, { ChargePayment, InitiateInvoicePayment, ViewInvoiceDetail } )(Invoice));