import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ViewAvailableLanguages } from '../../Services/Actions';

import { FlagContainer, FlagImage, FlagsContainer,  } from './styles';

import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class LanguageOptions extends React.Component {    
    componentDidMount() {
        this._isMounted = true;

        this.props.ViewAvailableLanguages();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        var { t } = this.props;
        var { AvailableLanguages, Language: CurrentLanguageId } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('language')} TextAlign="left" />

                <Spacer Size="small" />

                <FlagsContainer className="FlagsContainer">
                    {
                        AvailableLanguages.map(({ FlagUrl, LanguageId, LanguageName }) => {
                            var Selected = +CurrentLanguageId === +LanguageId;

                            return (
                                <FlagContainer className="FlagContainer" key={LanguageId} onClick={() => this.props.OnChangeLanguageId(LanguageId)} Selected={Selected}>
                                    <FlagImage ImageSrc={FlagUrl} SelfLoading />
                                    
                                    <PageText ElementType="span" FontColor={Selected ? 'blue-astronaut' : 'black'} FontFamily={Selected ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={LanguageName} TextAlign="left" />
                                </FlagContainer>
                            );
                        })
                    }
                </FlagsContainer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        AvailableLanguages: state.Auth.AvailableLanguages,

        TryingViewAvailableLanguages: state.Auth.TryingViewAvailableLanguages
    };
};

export default withTranslation()(connect(mapStateToProps, { ViewAvailableLanguages } )(LanguageOptions));