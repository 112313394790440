import React from 'react';
import { connect } from 'react-redux';
import { ProcessStageSubscriber } from '../../Services/Actions';
import history from '../../history';
import queryString from 'query-string';

import Loading from '../../Components/Loading';

class SubscriptionCallback extends React.Component {
    state = { Loading: true };

    componentDidMount() {
        var { ContractId, type } = this.props.match.params;

        const QueryValues = queryString.parse(this.props.location.search);

        var paymentid;
    
        if (Object.entries(QueryValues).length > 0 && QueryValues.paymentid) paymentid = QueryValues.paymentid;

        // const SubscriberId = localStorage.getItem("SubscriberId");

        // console.log('type: ', type);
        // console.log('ContractId: ', ContractId);
        // console.log('SubscriberId: ', SubscriberId);

        // if (type === 'AddSubscriber') this.props.ProcessStageSubscriber({ SubscriberId }).then(history.push(`/contract/${ContractId}?paymentid=${paymentid}`));
        if (type === 'AddSubscriber') history.push(`/contract/${ContractId}?paymentid=${paymentid}&contractSigned=pending`);
        else if (type === 'Cancel') history.push(`/contract/${ContractId}`);
        // else console.log('no type')

        // history.push('/account?section=subscriptions');
    }

    render() {
        if (this.state.Loading) return <Loading />;

        return null;
    }
}

export default connect(null, { ProcessStageSubscriber } )(SubscriptionCallback);