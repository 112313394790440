import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import TextArea from '../../Components/Form/TextArea';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const BackNextButtons = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const CheckingUserNameContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > i:first-child {
        margin-right: 15px;
    }
`;

export const CircleDiv = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 15px;
    margin-right: 5px;
    margin-top: 3px;
    width: 15px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const ContractParties = styled.div`
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
`;

export const DetailText = styled.div`
    ${props => props.FontColor && `color: var(--${props.FontColor});`}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily})` :  'var(--font-family-medium)'};
    font-size: var(--font-size-small);
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.Underline && 'text-decoration: underline;'}
`;

export const FakeCheckmarkBox = styled.div`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px;
        `
    :
        'border: 2px solid var(--black);'
    }

    border-radius: 5px;
    height: 20px;
    width: 20px;

    :not(:first-child) {
        margin-left: 5px;
    }
`;

export const FlagContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 27px;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: flex-start;
    padding: 15px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 15px;
    }

    @media ${device.minwidth.ipad} {
        width: 300px;
    }

    @media(hover:hover) {
        :hover {
            border: 2px solid var(--blue-astronaut);
            cursor: pointer;
        }
    }
`;

export const FlagImage = styled(Image)`
    height: 30px;
    margin-right: 15px;
`;

export const FlagsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FullNameText = styled.span`
    color: var(--${props => props.UserType === 'Trainer' ? 'purple' : 'blue-abel'});
    font-family: var(--font-family-semibold);
    text-transform: uppercase;
`;

export const HealthDeclarationAnswerContainer = styled.div``;

export const HealthDeclarationAnswerContainerAdditionalInfo = styled.div``;

export const HealthDeclarationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
`;

export const HealthDeclarationOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5px;

    > div:first-child {
        margin-right: 25px;
    }
`;

export const HealthDeclarationsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1000px;
`;

export const HealthDeclarationTextContainer = styled.div`
    flex: 1;
`;

export const InvoicePreviewAmountContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 10px;
    width: 120px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const InvoicePreviewContainer = styled.div``;

export const InvoicePreviewDateContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 5px;
    }

    margin-right: 10px;
`;

export const InvoicePreviewSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const NameContainer = styled.div``;

export const OnTimeContainer = styled.div`
    margin-left: 5px;
`;

export const PaidStatusContainer = styled.div`
    align-items: center;
    display: flex;
    // flex-basis: 100%;
    flex-direction: row;
    justify-content: flex-start;
`;

export const PersonalDetailsContainer = styled.div``;

export const ProductDescriptionContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const ProductDescriptionsContainer = styled.div``;

export const ProductsListBlockContainer = styled.div`
    margin-right: 70px;
    margin-top: 30px;
`;

export const ProductsListBlockRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 30px;

    
`;

export const ProductsListBlocksContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -70px;
    margin-top: -30px;
`;

export const ProductsListTableContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const ProductsListTableRowContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => !!props.IsSubscription ? '2fr 1fr 1fr' : '2fr 1fr 1fr 1fr'};
    width: 100%;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const QuestionContentContainer = styled.div``;

export const QuestionCount = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 17px;
    height: 17px;
    margin-right: 15px;
    width: 17px;
`;

export const QuestionCountContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const QuestionDotsContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;

export const RegistrationContainer = styled.div``;

export const ShowPasswordsContainer = styled.div`
    display: flex;
`;

export const StyledAccountInput = styled(Input)`
    ${props => props.MaxWidth && `width: ${props.MaxWidth};`}

    > input {
        background-color: var(--white-concrete);
        text-align: left;
        text-indent: 0px;
    }
`;

export const StyledAdditionalInfoTextArea = styled(TextArea)`
    flex: 1;

    > textarea {
        background-color: var(--white-concrete);
        border-radius: 10px;
        padding: 12px 16px;
    }
`;

export const StyledArrowIcon = styled(Image)`
    height: 20px;
    width: 20px;
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    grid-column: 1/-1;
    height: 2px;
    margin: 10px 0;
`;

export const StyledPaymentNumberPageText = styled(PageText)`
    width: 75px;
`;

export const StyledInput = styled(Input)`
    max-width: 300px;

    > input {
        background-color: var(--white-concrete);
        text-align: left;
        text-indent: 0px;
    }
`;

export const StyledProfilePhoto = styled(ProfilePhoto)``;

export const StyledSexButtonGroups = styled(ButtonGroup)`
    justify-content: flex-start;
    margin-top: 10px;
`;

export const TermsConditionsContainer = styled.div`
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    margin: 0 auto;
    max-width: 1000px;
    white-space: pre-wrap;
`;

export const TrainerOrganizationContainer = styled.div``;

export const TrainerOrganizationsContainer = styled.div``;

export const TrainerRequestContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
`;

export const TrainerRequestsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const UserDetailsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 1fr);

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const UserDetailsItemContainer = styled.div``;