import styled from 'styled-components';

export const DeliveryContainer = styled.div`
    align-items: stretch;
    border: 2px solid var(--black);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
`;

export const DeliveriesList = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
`;