import axios from 'axios';
import { RIDGE_API } from '../../Config';

import {
    HANDLE_VISIBILITY_CHANGE, RESIZE_WINDOW,

    TRYING_ACCEPT_CONTRACT, TRYING_ACCEPT_CONTRACT_SUCCESS, TRYING_ACCEPT_CONTRACT_ERROR,

    TRYING_CHARGE_PAYMENT, TRYING_CHARGE_PAYMENT_SUCCESS, TRYING_CHARGE_PAYMENT_ERROR,

    TRYING_GET_CITIES, TRYING_GET_CITIES_SUCCESS, TRYING_GET_CITIES_ERROR,
    TRYING_GET_COUNTRIES, TRYING_GET_COUNTRIES_SUCCESS, TRYING_GET_COUNTRIES_ERROR,
    TRYING_GET_STATES, TRYING_GET_STATES_SUCCESS, TRYING_GET_STATES_ERROR,

    TRYING_INITIATE_INVOICE_PAYMENT, TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS, TRYING_INITIATE_INVOICE_PAYMENT_ERROR,

    TRYING_REGISTER_USER, TRYING_REGISTER_USER_SUCCESS, TRYING_REGISTER_USER_ERROR,
    TRYING_REJECT_CONTRACT, TRYING_REJECT_CONTRACT_SUCCESS, TRYING_REJECT_CONTRACT_ERROR,
    TRYING_RESPOND_TO_TRAINER_REQUEST, TRYING_RESPOND_TO_TRAINER_REQUEST_SUCCESS, TRYING_RESPOND_TO_TRAINER_REQUEST_ERROR,
    TRYING_UPDATE_CONFIRM_DELIVERY, TRYING_UPDATE_CONFIRM_DELIVERY_SUCCESS, TRYING_UPDATE_CONFIRM_DELIVERY_ERROR,
    TRYING_VALIDATE_USER, TRYING_VALIDATE_USER_SUCCESS, TRYING_VALIDATE_USER_ERROR,
    TRYING_VIEW_AVAILABLE_LANGUAGES, TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS, TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR,
    TRYING_VIEW_CONFIRM_DELIVERY, TRYING_VIEW_CONFIRM_DELIVERY_SUCCESS, TRYING_VIEW_CONFIRM_DELIVERY_ERROR,
    TRYING_VIEW_CONTRACT_DETAIL, TRYING_VIEW_CONTRACT_DETAIL_SUCCESS, TRYING_VIEW_CONTRACT_DETAIL_ERROR,
    TRYING_VIEW_INVOICE_DETAIL, TRYING_VIEW_INVOICE_DETAIL_SUCCESS, TRYING_VIEW_INVOICE_DETAIL_ERROR
} from '../Types';

export const AcceptContract = ({ AccuracyStatement, ContractId, HealthDeclarations, SubscriberId, TermsConditions }) => async dispatch => {
    dispatch({ type: TRYING_ACCEPT_CONTRACT });

    var data = { AccuracyStatement, ContractId, HealthDeclarations, SubscriberId, TermsConditions };

    const response = await axios.post(`${RIDGE_API}invoices/AcceptContract`, { ...data });

    if (response.data.Response === 1) dispatch({ type: TRYING_ACCEPT_CONTRACT_SUCCESS });
    else dispatch({ type: TRYING_ACCEPT_CONTRACT_ERROR, payload: response.data.ErrorMessage });
}

export const ChargePayment = ({ InvoiceId, PaymentId }) => async dispatch => {
    dispatch({ type: TRYING_CHARGE_PAYMENT });

    var data = { InvoiceId, PaymentId };

    const response = await axios.post(`${RIDGE_API}invoices/ChargePayment`, { ...data });

    if (response.data.Response === 1) {
        dispatch({ type: TRYING_CHARGE_PAYMENT_SUCCESS });
    } else {
        dispatch({ type: TRYING_CHARGE_PAYMENT_ERROR, payload: response.data.ErrorMessage });
    }
}

export const EditUserProfile = ({ DOB, FirstName, FullAddress, Gender, LanguageId, LastName, UserId, UserName }) => async dispatch => {
    dispatch({ type: TRYING_REGISTER_USER });

    var data = { DOB, FirstName, FullAddress, Gender, LanguageId, LastName, UserId, UserName };

    const response = await axios.post(`${RIDGE_API}invoices/EditUserProfile`, { ...data });

    if (response.data.Response === 1) dispatch({ type: TRYING_REGISTER_USER_SUCCESS });
    else dispatch({ type: TRYING_REGISTER_USER_ERROR, payload: response.data.ErrorMessage });
}

export const HandleVisibilityChange = () => async dispatch => {
    dispatch({ type: HANDLE_VISIBILITY_CHANGE });
}

export const InitiateInvoicePayment = ({ IntegrationType, InvoiceId, ReturnUrl, Url }) => async dispatch => {
    dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT });

    var data = { IntegrationType, InvoiceId, ReturnUrl, Url };

    const response = await axios.post(`${RIDGE_API}invoices/InitiateInvoicePayment`, { ...data });

    if (response.data.Response === 1) {
        var { HostedPaymentPageUrl, PaymentId } = response.data;

        dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS });
        return { HostedPaymentPageUrl, PaymentId };
    } else {
        dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT_ERROR, payload: response.data.ErrorMessage });

        return {};
    }
}

export const InitiateSubscriptionAcceptancePayment = ({ CancelUrl, ContractId, HealthDeclarations, ReturnUrl, SubAmount, TimeZoneOffset, TrainerId, UserId }) => async dispatch => {
    dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT });

    var data = { CancelUrl, ContractId, HealthDeclarations, ReturnUrl, SubAmount, TimeZoneOffset, TrainerId, UserId };

    const response = await axios.post(`${RIDGE_API}invoices/InitiateSubscriptionAcceptancePayment`, { ...data });

    console.log('response: ', JSON.stringify(response.data));

    if (response.data.Response === 1) {
        var { HostedPaymentPageUrl, SubscriberId } = response.data;

        dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS });
        return { HostedPaymentPageUrl, SubscriberId };
    } else {
        dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT_ERROR, payload: response.data.ErrorMessage });

        return {};
    }
}

export const ProcessStageSubscriber = ({ SubscriberId }) => async dispatch => {
    dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT });

    var data = { SubscriberId };

    const response = await axios.post(`${RIDGE_API}invoices/ProcessSubscriptionStageSubscriber`, { ...data });

    if (response.data.Response === 1) {
        dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS });
    } else {
        dispatch({ type: TRYING_INITIATE_INVOICE_PAYMENT_ERROR, payload: response.data.ErrorMessage });
    }
}

export const RegisterUser = ({ DOB, Email, FirstName, Gender, LanguageId, LastName, Password, UserName }) => async dispatch => {
    dispatch({ type: TRYING_REGISTER_USER });

    var data = { DOB, Email, FirstName, Gender, LanguageId, LastName, Password, UserName };

    const response = await axios.post(`${RIDGE_API}invoices/RegisterUser`, { ...data });

    if (response.data.Response === 1) dispatch({ type: TRYING_REGISTER_USER_SUCCESS });
    else dispatch({ type: TRYING_REGISTER_USER_ERROR, payload: response.data.ErrorMessage });
}

export const RejectContract = ({ ContractId }) => async dispatch => {
    dispatch({ type: TRYING_REJECT_CONTRACT });

    var data = { ContractId };

    const response = await axios.post(`${RIDGE_API}invoices/RejectContract`, { ...data });

    if (response.data.Response === 1) dispatch({ type: TRYING_REJECT_CONTRACT_SUCCESS });
    else dispatch({ type: TRYING_REJECT_CONTRACT_ERROR, payload: response.data.ErrorMessage });
}

export const RespondToTrainerRequest = ({ RequestType, TrainerId, UserId }) => async dispatch => {
    dispatch({ type: TRYING_RESPOND_TO_TRAINER_REQUEST });

    var data = { RequestType, TrainerId, UserId };

    const response = await axios.post(`${RIDGE_API}profile/RespondToTrainerRequest`, { ...data });

    if (response.data.Response === 1) dispatch({ type: TRYING_RESPOND_TO_TRAINER_REQUEST_SUCCESS });
    else dispatch({ type: TRYING_RESPOND_TO_TRAINER_REQUEST_ERROR, payload: response.data.ErrorMessage });
}

export const ResizeWindow = () => async dispatch => {
    dispatch({ type: RESIZE_WINDOW });
}

export const SaveDataInProps = SaveData => async dispatch => {
    // dispatch({ type: SAVE_DATA_IN_PROPS, payload: SaveData });
}

export const SearchGoogleMapsAutocomplete = ({ Search }) => async dispatch => {
    var SearchUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?`;
    SearchUrl += `&input=${Search}`;
    SearchUrl += `&language=en&types=address`;
    SearchUrl += `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    const response = await axios.get(SearchUrl);

    return response.data.predictions;
}

export const SearchGoogleMapsPlaces = PlaceId => async dispatch => {
    var SearchUrl = `https://maps.googleapis.com/maps/api/place/details/json?`;
    SearchUrl += `&place_id=${PlaceId}`;
    SearchUrl += `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    const response = await axios.get(SearchUrl);

    return response.data.result.formatted_address;
}

export const UpdateConfirmDelivery = ({ ConfirmDeliveryId, DeliveryStatus }) => async dispatch => {
    dispatch({ type: TRYING_UPDATE_CONFIRM_DELIVERY });

    var data = { ConfirmDeliveryId, DeliveryStatus };

    const response = await axios.post(`${RIDGE_API}invoices/UpdateConfirmDelivery`, { ...data });

    if (response.data.Response === 1) {
        dispatch({ type: TRYING_UPDATE_CONFIRM_DELIVERY_SUCCESS });
    } else dispatch({ type: TRYING_UPDATE_CONFIRM_DELIVERY_ERROR, payload: response.data.ErrorMessage });
}

export const UpdateSelectedTab = SelectedTab => async dispatch => {
    // dispatch({ type: UPDATE_SELECTED_TAB, payload: SelectedTab });
}

export const ValidateUser = ({ UserName }) => async dispatch => {
    dispatch({ type: TRYING_VALIDATE_USER });
    var AuthToken = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("ABELPTAdmin2Token") } };

    var data = { UserName };

    const response = await axios.post(`${RIDGE_API}invoices/ValidateUser`, { ...data }, AuthToken);

    if (response.data.Response === 1) {
        var { UserNameTaken } = response.data;

        dispatch({ type: TRYING_VALIDATE_USER_SUCCESS });
        return { UserNameTaken };
    } else {
        dispatch({ type: TRYING_VALIDATE_USER_ERROR, payload: response.data.ErrorMessage });

        return {};
    }
}

export const ViewAvailableLanguages = () => async dispatch => {
    dispatch({ type: TRYING_VIEW_AVAILABLE_LANGUAGES });

    const response = await axios.post(`${RIDGE_API}invoices/ViewAvailableLanguages`, {});

    if (response.data.Response === 1) {
        var { Languages } = response.data;

        dispatch({ type: TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS, payload: { Languages } });
    } else dispatch({ type: TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR, payload: response.data.ErrorMessage });
}

export const ViewCities = ({ StateId }) => async dispatch => {
    dispatch({ type: TRYING_GET_CITIES });

    const response = await axios.post(`${RIDGE_API}invoices/ViewCities`, { StateId });

    if (response.data.Response === 1) dispatch({ type: TRYING_GET_CITIES_SUCCESS, payload: { Cities: response.data.Cities, StateId } });
    else dispatch({ type: TRYING_GET_CITIES_ERROR, payload: response.data.ErrorMessage });
}

export const ViewConfirmDelivery = ({ UserId }) => async dispatch => {
    dispatch({ type: TRYING_VIEW_CONFIRM_DELIVERY });

    const response = await axios.get(`${RIDGE_API}invoices/ViewConfirmDelivery/${UserId}`);

    if (response.data.Response === 1) {
        var { Deliveries } = response.data;

        dispatch({ type: TRYING_VIEW_CONFIRM_DELIVERY_SUCCESS, payload: { Deliveries } });
    } else dispatch({ type: TRYING_VIEW_CONFIRM_DELIVERY_ERROR, payload: response.data.ErrorMessage });
}

export const ViewContractDetail = ({ ContractId }) => async dispatch => {
    dispatch({ type: TRYING_VIEW_CONTRACT_DETAIL });

    const response = await axios.get(`${RIDGE_API}invoices/ViewContractDetail/${ContractId}`);

    if (response.data.Response === 1) {
        var { ContractDetail } = response.data;
        var { IsPreRegistered, TrainerRequests } = ContractDetail;

        if (ContractDetail.TrainerOrganizations === null) ContractDetail.TrainerOrganizations = [];

        dispatch({ type: TRYING_VIEW_CONTRACT_DETAIL_SUCCESS, payload: { ContractDetail, IsPreRegistered, TrainerRequests } });
    } else dispatch({ type: TRYING_VIEW_CONTRACT_DETAIL_ERROR, payload: response.data.ErrorMessage });
}

export const ViewCountries = () => async dispatch => {
    dispatch({ type: TRYING_GET_COUNTRIES });

    const response = await axios.post(`${RIDGE_API}invoices/ViewCountries`, {});

    if (response.data.Response === 1) dispatch({ type: TRYING_GET_COUNTRIES_SUCCESS, payload: { Countries: response.data.Countries } });
    else dispatch({ type: TRYING_GET_COUNTRIES_ERROR, payload: response.data.ErrorMessage });
}

export const ViewInvoiceDetail = ({ InvoiceId }) => async dispatch => {
    dispatch({ type: TRYING_VIEW_INVOICE_DETAIL });

    const response = await axios.get(`${RIDGE_API}invoices/ViewInvoiceDetail/${InvoiceId}`);

    if (response.data.Response === 1) {
        var { ContractDetails, InvoiceDetails } = response.data;

        dispatch({ type: TRYING_VIEW_INVOICE_DETAIL_SUCCESS, payload: { InvoiceDetail: { ContractDetails, InvoiceDetails } } });
    } else dispatch({ type: TRYING_VIEW_INVOICE_DETAIL_ERROR, payload: response.data.ErrorMessage });
}

export const ViewStates = ({ CountryId }) => async dispatch => {
    dispatch({ type: TRYING_GET_STATES });

    const response = await axios.post(`${RIDGE_API}invoices/ViewStates`, { CountryId });

    if (response.data.Response === 1) dispatch({ type: TRYING_GET_STATES_SUCCESS, payload: { States: response.data.States, CountryId } });
    else dispatch({ type: TRYING_GET_STATES_ERROR, payload: response.data.ErrorMessage });
}