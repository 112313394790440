import styled from 'styled-components';

import { device } from '../../Config/device';

export const Container = styled.div`
    margin: ${props => props.NoMargin ? '0' : 'var(--spacer-size-medium)'} auto;

    @media ${device.minwidth.mobile_small} { width: 100%; }
    @media ${device.minwidth.mobile} { width: 100%; }
    @media ${device.minwidth.ipad} { width: 87%; }
    @media ${device.minwidth.laptop} { width: 85%; }
`;

// Container width: ${props => props.PageWidth ? `${props.PageWidth}%` : props.NoMargin ? '100%' : '85%'};

export const MainContentContainer = styled.div`
    margin: var(--spacer-size-small) auto;

    @media ${device.minwidth.mobile_small} { width: 93%; }
    @media ${device.minwidth.mobile} { margin: var(--spacer-size-medium) auto; width: 90%; }
    @media ${device.minwidth.ipad} { width: 80%; }
    @media ${device.minwidth.laptop} { width: 80%; }
`;

// MainContentContainer width: ${props => props.ContentWidth ? props.ContentWidth : '85%'};

export const PageContainer = styled.div`
    background-color: ${props => props.NoBackground ? 'inherit' : 'var(--white)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-height: 50px;
`;