import {
    TRYING_CHARGE_PAYMENT,
    TRYING_CHARGE_PAYMENT_SUCCESS,
    TRYING_CHARGE_PAYMENT_ERROR,

    TRYING_INITIATE_INVOICE_PAYMENT,
    TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS,
    TRYING_INITIATE_INVOICE_PAYMENT_ERROR,

    TRYING_VIEW_INVOICE_DETAIL,
    TRYING_VIEW_INVOICE_DETAIL_SUCCESS,
    TRYING_VIEW_INVOICE_DETAIL_ERROR
} from '../Types';

const INITIAL_STATE = {
    InvoiceDetail: {},

    TryingChargePayment: false,
    TryingChargePaymentError: null,
    TryingInitiateInvoicePayment: false,
    TryingInitiateInvoicePaymentError: null,
    TryingViewInvoiceDetail: false,
    TryingViewInvoiceDetailError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_CHARGE_PAYMENT:
            return { ...state, TryingChargePayment: true, TryingChargePaymentError: null };
        case TRYING_CHARGE_PAYMENT_ERROR:
            return { ...state, TryingChargePayment: false, TryingChargePaymentError: action.payload };
        case TRYING_CHARGE_PAYMENT_SUCCESS:
            return { ...state, TryingChargePayment: false, TryingChargePaymentError: null };

        case TRYING_INITIATE_INVOICE_PAYMENT:
            return { ...state, TryingInitiateInvoicePayment: true, TryingInitiateInvoicePaymentError: null };
        case TRYING_INITIATE_INVOICE_PAYMENT_ERROR:
            return { ...state, TryingInitiateInvoicePayment: false, TryingInitiateInvoicePaymentError: action.payload };
        case TRYING_INITIATE_INVOICE_PAYMENT_SUCCESS:
            return { ...state, TryingInitiateInvoicePayment: false, TryingInitiateInvoicePaymentError: null };

        case TRYING_VIEW_INVOICE_DETAIL:
            return { ...state, TryingViewInvoiceDetail: true, TryingViewInvoiceDetailError: null };
        case TRYING_VIEW_INVOICE_DETAIL_ERROR:
            return { ...state, TryingViewInvoiceDetail: false, TryingViewInvoiceDetailError: action.payload };
        case TRYING_VIEW_INVOICE_DETAIL_SUCCESS:
            return { ...state, InvoiceDetail: action.payload.InvoiceDetail, TryingViewInvoiceDetail: false, TryingViewInvoiceDetailError: null };
        
        default:
            return state;
    }
};
