import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { HandleVisibilityChange, ResizeWindow } from './Services/Actions';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import history from './history';

import styled from 'styled-components';

import ConfirmDelivery from './Pages/ConfirmDelivery';
import Contract from './Pages/Contract';
import Invoice from './Pages/Invoice';
import SubscriptionCallback from './Pages/SubscriptionCallback';

import { SetLanguage } from './Functions';

import "./Assets/Translations";
import './index.css';

const BodyContainer = styled.div`
    align-items: unset;
    background-color: var(--gray-light);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: unset;
    
    min-height: 100%;
    overflow: scroll;
`;

class App extends React.Component {
    state = { CurrentPathname: '' };

    componentDidMount() {
        this.onSetupLanguage();

        // Set Router To Watch Changes
            this.unlisten = history.listen(location => this.onUpdateCurrentLocation(location.pathname));

        // Set Window to Listen For Browser Resize
        // Set Window to Listen For Tab Being Visible / Hidden
            this.props.HandleVisibilityChange();
            this.props.ResizeWindow();
            window.addEventListener('resize', this.props.ResizeWindow);
            document.addEventListener('visibilitychange', this.props.HandleVisibilityChange);
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.props.ResizeWindow);
        document.removeEventListener('visibilitychange', this.props.HandleVisibilityChange);
    }

    onSetupLanguage = () => {
        SetLanguage(1);
    }

    onUpdateCurrentLocation = CurrentPathname => {
        this.setState({ CurrentPathname });
    }

    render = () => {
		return (
            <BodyContainer>
                <Router history={history}>
                    <Switch>
                        <Route path="/Contract/:ContractId" exact component={Contract} />
                        <Route path="/subscription/callback/:type/:ContractId" exact component={SubscriptionCallback} />
                        <Route path="/Invoice/:InvoiceId" exact component={Invoice} />
                        <Route path="/ConfirmDelivery/:UserId" exact component={ConfirmDelivery} />

                        <Redirect to="/" />
                    </Switch>
                </Router>
            </BodyContainer>
		);
    }
}

const mapStateToProps = state => {
    return {
        WindowVisible: state.Window.WindowVisible
    };
};

export default withTranslation()(connect(mapStateToProps, { HandleVisibilityChange, ResizeWindow } )(App));